import { SHOP_OWNERS_URL } from "../constants/constants";
import { apiSlice } from "./apiSlice";

export const shopOwnersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        shopOwnerLogin: builder.mutation({
            query: (data) => ({
                url: `${SHOP_OWNERS_URL}/auth`,
                method: 'POST',
                body: data,
            }),
        }),
        registerShopOwner: builder.mutation({
            query: (data) => ({
                url: `${SHOP_OWNERS_URL}`,
                method: 'POST',
                body: data,
            }),
        }),
        getShopOwnerById: builder.query({
            query: (id) => `${SHOP_OWNERS_URL}/${id}`,
            providesTags: ["ShopOwner"],
        }),
        getShopOwnerByShopId: builder.query({
            query: (id) => `${SHOP_OWNERS_URL}/shop/${id}`,
            providesTags: ["ShopOwner"],
        }),
        getShopOwnerBySlug: builder.query({
            query: (slug) => `${SHOP_OWNERS_URL}/slug/${slug}`,
            providesTags: ["ShopOwner"],
        }),
        getMyShopOwnerProfile: builder.query({
            query: () => `${SHOP_OWNERS_URL}/profile`,
            providesTags: ["ShopOwner"],
        }),
        verifyCurrentShopOwnerPassword: builder.mutation({
            query: (data) => ({
                url: `${SHOP_OWNERS_URL}/verify-password`,
                method: 'POST',
                body: data,
            }),
        }),
        updateMyShopOwnerProfile: builder.mutation({
            query: (data) => ({
                url: `${SHOP_OWNERS_URL}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ["ShopOwner"],
        }),
        updateShopOwner: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `${SHOP_OWNERS_URL}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ["ShopOwner"],
        }),
        deleteShopOwner: builder.mutation({
            query: (id) => ({
                url: `${SHOP_OWNERS_URL}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["ShopOwner"],
        }),
    }),
});

export const {
    useShopOwnerLoginMutation,
    useRegisterShopOwnerMutation,
    useGetShopOwnerByIdQuery,
    useGetShopOwnerByShopIdQuery,
    useGetShopOwnerBySlugQuery,
    useGetMyShopOwnerProfileQuery,
    useVerifyCurrentShopOwnerPasswordMutation,
    useUpdateMyShopOwnerProfileMutation,
    useUpdateShopOwnerMutation,
    useDeleteShopOwnerMutation,
} = shopOwnersApiSlice;